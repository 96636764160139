export default {
  'boat-loan-payment-calculator': '/boat-loan-payment-calculator',
  'how-to-finance-a-boat': '/how-to-finance-a-boat',
  'best-boat-loan-rates': '/best-boat-loan-rates',
  'usaa-boat-loans': '/usaa-boat-loans',
  'best-used-boat-loans': '/best-used-boat-loans',
  'how-to-get-a-boat-loan-with-bad-credit':
    '/how-to-get-a-boat-loan-with-bad-credit',
  'privacy-policy': '/privacy-policy',
  'terms-and-conditions': `/terms-and-conditions`,
  'contact-us': '/contact-us',
  'boats-search': '/boats',
  'get-started': '/get-started',
  'apply-now': '/apply-now',
};
