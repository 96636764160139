import React from 'react';
import NavLink from './NavLink';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Container, Text, Button, Spacer } from '@nextui-org/react';
import { MdOutlineClose as CloseIcon } from 'react-icons/md';
import { HiOutlineMenuAlt4 as HamburgerIcon } from 'react-icons/hi';
import { track } from '@amplitude/analytics-browser';
import Routes from '../routes';

type Props = {
  routes: Array<{
    route: string;
    label: string;
  }>;
  isOpen: boolean;
  onClose: () => void;
};

const MobileNavItem = ({ to, label, index = 0, isActive, onClick }) => (
  <Link href={to}>
    <a
      css={{
        minWidth: '100%',
        color: isActive ? 'white' : 'rgba(255, 255, 255, 0.6)',
      }}
      onClick={onClick}
    >
      {label}
    </a>
  </Link>
);

const MobileNav = ({ isOpen, routes, onClose }: Props) => {
  const router = useRouter();
  const currentRoute = router.pathname;

  const disableScrolling = React.useCallback(() => {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }, []);

  const enableScrolling = React.useCallback(() => {
    window.onscroll = function () {};
  }, []);

  // disable scrolling while the mobile nav is open
  React.useEffect(() => {
    if (isOpen) {
      disableScrolling();
    }
  }, [isOpen, disableScrolling]);

  // enable scrolling when the mobile nav is closed
  const OnClose = React.useCallback(() => {
    enableScrolling(0);
    onClose();
  }, [enableScrolling, onClose]);

  if (!isOpen) return null;
  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 600,
        background: 'rgba(0,0,0, .9)',
      }}
    >
      {/* Header */}
      <div
        css={{
          height: '100%',
          width: '100%',
          background: 'rgb(0,0,0)',
          background:
            'linear-gradient(180deg, rgba(0,0,0,.8) 50%,  rgba(32,0,255,.2) 100%)',
        }}
      >
        <div
          css={{
            height: '75px', // height of navbar (so the header of the mobile nav stays the same as the navbar)
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '24px',
            paddingRight: '24px',
            justifyContent: 'space-between',

            '@media only screen and (max-width: 650px)': {
              paddingLeft: '12px',
              paddinRight: '12px',
            },
          }}
        >
          <MobileNavToggleButton onClick={OnClose} isOpen={isOpen} />
          <Text h2 size={24} color="rgba(255,255,255,0.6)">
            LendingBoat
          </Text>
        </div>
        <Spacer />

        {/* Content */}
        <div
          css={{
            height: '100%',
            width: '100%',

            paddingLeft: '24px',

            '@media only screen and (max-width: 650px)': {
              paddingLeft: '18px',
            },
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {routes.map((item, index) => (
              <MobileNavItem
                key={`collapse_${item.route}`}
                to={item.route}
                label={item.label}
                index={index}
                isActive={item.route === currentRoute}
                onClick={OnClose}
              />
            ))}
          </div>

          <Spacer />
          <Button
            onClick={({ target }) => {
              track('navbar button apply now click');
              router.push(Routes['apply-now']);
              if (target instanceof HTMLElement) {
                target.blur();
              }
              OnClose();
            }}
            disabled={currentRoute === Routes['apply-now']}
            auto
            ghost
            color="gradient"
          >
            Apply for financing now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;

export const MobileNavToggleButton = ({
  onClick,
  isOpen,
}: {
  onClick: () => void;
}) => {
  return (
    <div
      css={{
        button: {
          border: 'none',
          fontSize: '30px',
        },
      }}
    >
      <Button
        ghost
        rounded
        auto
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        onClick={onClick}
        ripple={false}
      />
    </div>
  );
};
