import React from 'react';
import Link from 'next/link';

const NavLink = ({ href, children, isActive }) => (
  <Link href={href}>
    <a
      css={{
        color: isActive ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.8)',
      }}
    >
      {children}
    </a>
  </Link>
);

export default NavLink;
