import React from 'react';
import { Container, Text } from '@nextui-org/react';
import MailingListSignup from './MailingListSignup';
import Link from 'next/link';
import Routes from '../routes.ts';

const Footer = () => {
  return (
    <footer
      css={{
        width: '100vw',
        position: 'absolute',
        bottom: 0,
        left: 0,
        paddingTop: '100px',
        height: '200px',
      }}
    >
      <div
        css={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          'flex-wrap': 'wrap',
          gap: '24px',
          '@media only screen and (max-width: 760px)': {
            flexDirection: 'column',
          },
          maxWidth: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            gap: '24px',
          }}
        >
          <Link href="/">
            <a>
              <Text size={24} h2>
                LendingBoat <span css={{ opacity: 0.5 }}>™</span>
              </Text>
            </a>
          </Link>
          <Text color="rgba(255,255,255,0.4)">
            &copy; Copyright {new Date().getFullYear()}, LendingBoat
          </Text>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            color: 'grey',
            a: {
              color: 'grey',
            },
          }}
        >
          <Text h5 color="rgba(255,255,255,0.8)">
            Help and Solutions
          </Text>
          <ul>
            <li>
              <Link href={Routes['privacy-policy']}>Privacy Policy</Link>
            </li>
            <li>
              <Link href={Routes['terms-and-conditions']}>
                Terms And Conditions
              </Link>
            </li>
            <li>
              <Link href={Routes['contact-us']}>Contact Us</Link>
            </li>
            <li>
              <Link href="/sitemap.xml">Site Map</Link>
            </li>
          </ul>
        </div>
        <div>
          <MailingListSignup />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
