import React from 'react';
import { useInfiniteQuery, useQuery, QueryClient } from '@tanstack/react-query';
import { Nullable } from '../types';
import { NormalizedBoat, BoatParams } from '../types/boat';
import { type Filters } from '../types/search';
import { normalizeBoatData, createBoatTraderSearchQuery } from '../utils/boat';

export const API_KEY = '8b08b9bc353c494a80c60fb86debfc56';
export const API_URL = 'https://api.boattrader.com/app';
export const BOATS_QUERY_KEY = 'boats';

const queryClient = new QueryClient();

export const useBoats = ({
  pageSize,
  filters,
}: {
  pageSize: number;
  filters: Filters;
}): {
  pageSize: number;
  isLoading: boolean; // initial load state
  totalCount: number;
  boats: any;
  isFetching: boolean; // pagination load state
} => {
  const filterParams = React.useMemo(
    () => createBoatTraderSearchQuery({ filters }),
    [filters]
  );
  const filterQueryStrings = Object.entries(filterParams).reduce(
    (acc, [key, value]) => {
      if (value) return `${acc}&${key}=${value}`;
      return acc;
    },
    ''
  );

  const fetchPage = React.useCallback(
    ({ pageParam = 1 }) =>
      fetch(
        `${API_URL}/search/boat?apikey=${API_KEY}&pageSize=${pageSize}&page=${pageParam}&country=US&${filterQueryStrings}`
      ).then((res) => res.json()),
    [pageSize, filterQueryStrings]
  );

  const { isLoading, error, data, isFetching, fetchNextPage, refetch } =
    useInfiniteQuery(['boats', filters], fetchPage, {
      getNextPageParam: (lastPage, pages) => pages.length,
    });

  React.useEffect(() => {
    // reset and refresh using the new filters
    queryClient.invalidateQueries(['boats'], {
      refetchType: 'active',
    });
  }, [filterParams]);

  const pages = data?.pages || [];
  const totalCount = pages?.[0]?.search?.count;

  const hasMore = (() => {
    if (isLoading) return true; // otherwise the initial load will initially say there is no more
    if (!data) return false; // just in case
    const expectedPages = Math.ceil(totalCount / pageSize);
    return expectedPages > data?.pages?.length;
  })();

  return {
    isLoading,
    error,
    pages,
    totalCount,
    pageSize,
    isFetching,
    fetchNextPage,
    hasMore,
  };
};

export const useBoatDetails = (
  params: Nullable<BoatParams>
): { isLoading: boolean; boat: Nullable<NormalizedBoat> } => {
  const { year, make, model, id } = params ?? {};

  const { data, isLoading } = useQuery(
    ['boats', id],
    () =>
      fetch(
        `${API_URL}/search/boat?apikey=${API_KEY}&pageSize=20&page=1&country=US&multiFacetedMakeModel=["${make}",["${model}"]]&year=${year}-${year}&exactMakeMatch=true`
      ).then((res) => res.json()),
    { enabled: Boolean(params) }
  );

  const boats = data?.search?.records || [];
  const boat = boats.find((boat) => String(boat.id) === id);

  if (isLoading || !boat) {
    return { isLoading, boat: null };
  }

  return { isLoading, boat: normalizeBoatData(boat) };
};
