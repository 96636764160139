export const isYoutubeURL = (url) => {
  const regex = new RegExp(
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/g
  );
  return regex.test(url);
};

export const getYoutubeIdFromURL = (url) => {
  var regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    //error
    return '';
  }
};
