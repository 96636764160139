import Routes from './routes'

const DEFAULT = {
  title: 'The Modern Way To Buy A Boat',
  description:
    'Monthly Payment Calculator. · How To Finance A Boat. · Best Boat Loans For Used Boats. · Best Boat Loan Rates'
}

export default {
  url: 'https://lending.boats',
  default: DEFAULT,
  '/': DEFAULT,
  '/404': DEFAULT,
  [Routes['boat-loan-payment-calculator']]: {
    title: 'Boat Payment Calculator: Estimate Your Monthly Payment',
    description:
      'How much boat can you afford? This free payment calculator will help you determine the monthly payments on your boat loan.'
  },
  [Routes['how-to-finance-a-boat']]: {
    title: 'Boat buying 101: How To Finance A Boat',
    description:
      'Determine how much money you can borrow. · Get Pre-Qualified. Shop around for low interest rates'
  },
  [Routes['best-used-boat-loans']]: {
    title: 'Top 4 Best Loans For Used Boats: Compare Requirments',
    description:
      'Financing a used boat is very common. · Lightstream. · Bank Of America. · Marcus by Goldman Sachs. · Wells Fargo'
  },
  [Routes['best-boat-loan-rates']]: {
    title: 'Top 10 best boat loan rates | 2022 interest rates',
    description:
      'Compare the best boat loan rates. Borrowers with good credit can expect an interest rate between 4% and 5% with higher rates around 7%.'
  },
  [Routes['usaa-boat-loans']]: {
    title: 'USAA Boat Loans: Best for Military Families and Members',
    description:
      "If you're in the military and looking for a boat loan, USAA is an excellent choice. The members-only financial institution offers some of the lowest rates on the market with no fees or closing costs,"
  },
  [Routes['how-to-get-a-boat-loan-with-bad-credit']]: {
    title: 'How To Get A Boat Loan With Bad Credit',
    description:
      "If you have bad credit, it's important to understand that it doesn't necessarily mean you won't get a boat loan. There are several ways to increase your chances of approval and lower the risk of losing out on the perfect boat for your needs."
  },
  [Routes['privacy-policy']]: {
    title: 'Privacy Policy'
  },
  [Routes['terms-and-conditions']]: {
    title: 'Terms And Conditions'
  },
  [Routes['contact-us']]: {
    title: 'Contact Us'
  },
  [Routes['boats-search']]: {
    title: 'Find the boat that is right for you',
    description: 'View our inventory of over 85,000 boats for sale'
  },
  [Routes['apply-now']]: {
    title: 'Easy Online Boat Financing | Apply Now'
  },
  [Routes['get-started']]: {
    title: 'The first 3 steps in buying a boat',
    description: ' Buying a boat can be a confusing task. Our guide is here to help you navigate through the various steps required to get you on the water.'
  },
  '/boats/[...boat]': { /* boat details will set its metadata inline */}
}
