import React from 'react';
import { createTheme, NextUIProvider, Spacer } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import BoatPreviewCarousel from '../components/BoatPreviewCarousel';
import footerCarouselBlacklist from '../utils/footerCarouselBlacklist';

import Head from 'next/head';
import Script from 'next/script';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
const queryClient = new QueryClient();

// Run dev validations during build time
import { __runAllBuildTimeValidations } from '../dev-utils.ts';
if (typeof window === 'undefined') __runAllBuildTimeValidations();

import Layout from '../components/layout';
import SEO from '../components/SEO';

const lightTheme = createTheme({
  type: 'light',
});

const darkTheme = createTheme({
  type: 'dark',
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const currentRoute = router.pathname;
  const isProduction = process.env.NODE_ENV === 'production';

  React.useEffect(() => {
    amplitude.init('6920042a8fd23c53729c352c2808bc7e');
  }, []);

  React.useEffect(() => {
    if (isProduction) {
      amplitude.logEvent('page_view', { path: currentRoute });
    } else {
      // dont sent analytics in dev
      amplitude.setOptOut(true);
    }
  });

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link
          href="https://fonts.googleapis.com/css2?family=Raleway&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
          rel="stylesheet"
        />
      </Head>
      {isProduction && (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-GBSXNJ8RCT"
            strategy="afterInteractive"
          />

          <Script id="google-analytics" strategy="afterInteractive">
            {`      
                console.log('GA loaded');
                window.dataLayer = window.dataLayer || []; 
                function gtag(){window.dataLayer.push(arguments)}; 
                gtag('js', new Date()); 

                gtag('config', 'G-GBSXNJ8RCT');
          `}
          </Script>
          <Script id="Hotjar" strategy="afterInteractive">
            {`
            console.log('Hotjar loaded');
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3204745,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
          </Script>
        </>
      )}
      <div
        css={{
          '*': {
            'font-family': '"Open Sans", sans-serif !important',
            '--nextui-fonts-sans': '"Open Sans", sans-serif !important',
          },
          'h1, h2, h3, h4': {
            'font-family': 'Raleway, sans-serif !important',
            '--nextui-fonts-sans': 'Raleway, sans-serif !important',
          },
        }}
      >
        <NextThemesProvider
          defaultTheme="dark"
          attribute="class"
          value={{
            light: lightTheme.className,
            dark: darkTheme.className,
          }}
        >
          <NextUIProvider>
            <QueryClientProvider client={queryClient}>
              <Layout>
                <SEO route={currentRoute} />
                <Component {...pageProps} />

                {!footerCarouselBlacklist.includes(currentRoute) && (
                  <>
                    <Spacer y={6} />
                    <BoatPreviewCarousel title="Boats you may like" />
                  </>
                )}
              </Layout>
            </QueryClientProvider>
          </NextUIProvider>
        </NextThemesProvider>
      </div>
    </>
  );
}

export default MyApp;
