import React, { FC, ReactNode } from 'react';
import Document, { Html, Head, Main, NextScript } from 'next/document';
import { CssBaseline, Container, Spacer } from '@nextui-org/react';
import Navbar from './Navbar';
import Footer from './Footer';

type Props = { children: ReactNode };
const Layout: FC<Props> = ({ children }) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          minHeight: '100vh', // make sure footer is always at the very bottom even if the page contents are short
          width: '1400px',
          maxWidth: '100%',
        }}
      >
        <Navbar />
        <Container
          display="flex"
          direction="column"
          css={{
            maxWidth: '100%',
            padding: '12px !important',
            overflowX: 'hidden',
          }}
        >
          {children}
        </Container>
        <Spacer y={12} />
        <Footer />
        <Spacer y={4} />
      </div>
    </div>
  );
};

export default Layout;
