import React from 'react';
import Youtube from 'react-youtube';

const YoutubePlayer = ({ id, height = '100%', width = '100%' }) => {
  if (!id) return null;
  const opts: YouTubeProps['opts'] = {
    height,
    width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return <Youtube videoId={id} opts={opts} />;
};

export default YoutubePlayer;
