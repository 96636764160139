import React from 'react';
import Link from 'next/link';
import { Navbar, Button } from '@nextui-org/react';
import { TbSpeedboat } from 'react-icons/tb';
import styled from 'styled-components';
import Routes from '../routes.ts';
import { useRouter } from 'next/router';
import MobileNavDrawer, { MobileNavToggleButton } from './MobileNavDrawer';
import NavLink from './NavLink';
import { track } from '@amplitude/analytics-browser';

const MainIcon = styled(TbSpeedboat)`
  font-size: 32px;
  margin-right: 10px;
`;

const LinkDivider = styled.div`
  height: 50%;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
`;

const NavItems = [
  {
    label: 'Home',
    route: '/',
    topNav: false,
  },
  {
    label: 'Get Started',
    route: Routes['get-started'],
    topNav: true,
  },
  {
    label: 'Browse Inventory',
    route: Routes['boats-search'],
    topNav: true,
    hideNavbarOnScroll: true,
  },
  {
    label: 'How to finance a boat',
    route: '/how-to-finance-a-boat',
    topNav: true,
    hideNavbarOnScroll: true,
  },
  {
    label: 'Current rates',
    route: '/best-boat-loan-rates',
    topNav: true,
  },
  {
    label: 'USAA Boat Loans',
    route: '/usaa-boat-loans',
    topNav: false,
    hideNavbarOnScroll: true,
  },
  {
    label: 'Best used boat loans',
    route: '/best-used-boat-loans',
    topNav: false,
    hideNavbarOnScroll: true,
  },
  {
    label: 'How to get a boat loan with bad credit',
    route: '/how-to-get-a-boat-loan-with-bad-credit',
    topNav: false,
    hideNavbarOnScroll: true,
  },
];

const Nav = () => {
  const router = useRouter();
  const currentRoute = router.pathname;
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = React.useState(false);

  const CtaButtonProps = (() => {
    switch (currentRoute) {
      case '/':
        return {
          color: 'gradient',
        };
      case Routes['boat-loan-payment-calculator']:
        return {
          disabled: true,
        };
      default:
        return {
          color: 'gradient',
        };
    }
  })();

  const shouldHideOnScroll = NavItems.find(
    ({ route }) => route === currentRoute
  )?.hideNavbarOnScroll;

  const toggleMobileDrawer = React.useCallback(() => {
    setIsMobileDrawerOpen((isOpen) => !isOpen);
  }, []);

  return (
    <>
      <Navbar
        isBordered={false}
        variant="sticky"
        shouldHideOnScroll={shouldHideOnScroll}
      >
        <div
          css={{
            // hide the hamburger menu on desktop
            '@media only screen and (min-width: 960px)': {
              display: 'none',
            },
          }}
        >
          <MobileNavToggleButton
            isOpen={isMobileDrawerOpen}
            onClick={toggleMobileDrawer}
          />
        </div>
        <Link href="/">
          <a>
            <Navbar.Brand
              hideIn="sm"
              css={{
                color: 'white',
                '@md': {
                  w: '12%',
                },
              }}
            >
              <MainIcon />
            </Navbar.Brand>
          </a>
        </Link>

        <Navbar.Content hideIn="sm">
          {NavItems.filter(({ topNav }) => Boolean(topNav)).map((item, i) => (
            <div key={item.route}>
              <NavLink href={item.route} isActive={item.route === currentRoute}>
                {item.label}
              </NavLink>
              {i < NavItems.length - 1 && <LinkDivider />}
            </div>
          ))}
        </Navbar.Content>

        <Navbar.Content>
          <Navbar.Item>
            <Button
              onClick={() => {
                track('navbar button calculator click');
                router.push(Routes['boat-loan-payment-calculator']);
              }}
              {...CtaButtonProps}
              auto
            >
              Payment Calculator
            </Button>
          </Navbar.Item>
        </Navbar.Content>
      </Navbar>
      <MobileNavDrawer
        isOpen={isMobileDrawerOpen}
        onClose={toggleMobileDrawer}
        routes={NavItems}
      />
    </>
  );
};

export default Nav;
