import React from 'react';

import BoatCard from './BoatCard';
import { normalizeBoatData } from '../utils/boat';
import { NormalizedBoat } from '../types/boat';

const filterShitListings = (boat: NormalizedBoat) => {
  // remove bad quality listing like those that dont have images

  if (!boat?.media?.length) return false;
  // if (boat?.price?.hidden) return false;

  return true;
};

const BoatCardList = ({
  boats,
  smallCards,
  horizontal,
}: {
  boats: Array<NormalizedBoat>;
  smallCards?: boolean;
  horizontal?: boolean;
}) => {
  return (
    <>
      <div
        css={{
          display: 'flex',
          flexDirection: horizontal ? 'row' : 'column',
          gap: '18px',
          paddingBottom: '18px', // space between pages
          maxWidth: '100%',
          width: '100%',
        }}
      >
        {boats
          .filter(filterShitListings)
          .map(normalizeBoatData)
          .map((boat) => (
            <BoatCard key={boat.id} {...boat} smallCard={smallCards} />
          ))}
      </div>
    </>
  );
};

export default BoatCardList;
