import React from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import {
  Text,
  Button,
  Input,
  Container,
  Spacer,
  Loading,
} from '@nextui-org/react';
import { FiSend } from 'react-icons/fi';
import { track } from '@amplitude/analytics-browser';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Label = styled.label`
  color: rgba(255, 255, 255, 0.9);
`;

const TextInput = styled(Input)`
  height: 35px;
`;
const SubmitButton = styled(Button)`
  width: 90px;
  border: none;
  border-radius: 0 4px 4px 0;
  background-color: #ffc300;
  color: black;
  font-size: 1.05rem;
`;
const ThankYouText = styled.p``;

const formName = 'join_mailing_list';

const EmailInput = ({ noTitle }: { noTitle?: boolean }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmitDone, setIsSubmitDone] = React.useState(false);

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      track('join_mailing_list', { email });
      setIsSubmitDone(false); // just in case we are trying again sometime
      setIsSubmitting(true);

      // We are using Netlify forms for this: https://docs.netlify.com/forms/setup/#submit-javascript-rendered-forms-with-ajax
      const params = new URLSearchParams();
      params.set('form-name', formName); // form-name is the name of the form in Netlify
      params.set('email', email);

      const { status, statusText } = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: params.toString(),
      });
      if (status !== 200) {
        throw new Error(statusText);
      }
    } catch (e: any) {
      typeof window !== 'undefined' &&
        // @ts-expect-error gtag exist on global
        window.gtag('event', 'exception', {
          description: e?.message,
          fatal: false,
        });
    } finally {
      setIsSubmitting(false);
      setIsSubmitDone(true);
    }
  };

  return (
    <div>
      <Formik initialValues={{ email: '' }} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form name={formName} data-netlify="true">
            {!isSubmitDone ? (
              <div>
                {!noTitle && (
                  <Text
                    htmlFor="email"
                    color="rgba(255,255,255,0.8)"
                    css={{
                      maxWidth: '260px',
                    }}
                  >
                    Lend us your email and we might send you a sticker
                  </Text>
                )}
                <Spacer />
                <Row>
                  <Field
                    as={Input}
                    name="email"
                    type="email"
                    placeholder="email"
                    required
                    color="gold"
                    rounded
                    bordered
                    css={{
                      'border-radius': 0,
                    }}
                    size="md"
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    data-hj-allow
                  />
                  <button
                    type="submit"
                    disabled={!values.email}
                    ghost="true"
                    disabled={isSubmitting}
                    size="sm"
                    css={{
                      background: 'transparent',
                      border: 'none',
                      'margin-left': '5px',
                      padding: '10px',
                      color: 'gold',
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center',
                      'font-size': '24px',
                    }}
                  >
                    {isSubmitting ? (
                      <Loading type="points-opacity" />
                    ) : (
                      <FiSend />
                    )}
                  </button>
                </Row>
              </div>
            ) : (
              <ThankYouText>⛵ Thank you, we will be in touch ⛵</ThankYouText>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmailInput;
