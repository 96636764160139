import { ParsedUrlQuery } from 'querystring';
import { Nullable } from '../types';
import {
  RawBoat,
  NormalizedBoat,
  BoatParams,
  NormalizedBoatMedia,
} from '../types/boat';
import { type Filters } from '../types/search';

export const getBoatParams = ({
  boat,
}: ParsedUrlQuery): Nullable<BoatParams> => {
  if (!Array.isArray(boat) || boat.length !== 4) {
    return null;
  }

  const [year, make, model, id] = boat;

  // 2023/trifecta/24rfc-cs/8341750
  // year/manufacturer/model/id
  return { year, make, model, id };
};

export const createBoatTraderSearchQuery = ({
  filters,
}: {
  filters: Filters;
}) => {
  const { power, sail, condition, make, model, year, price, length, location } =
    filters;

  const multiFacetedBoatTypeClass = (() => {
    if (!power && !sail) return undefined;
    if (power) return '["power"]';
    if (sail) return '["sail"]';
    if (power && sail) return '["power"]|["sail"]';
  })();

  const conditionStr = (() => {
    if (!condition || condition.new === condition.used) return undefined;
    if (condition.new) return 'new';
    if (condition.used) return 'used';
  })();

  const state = location?.state;

  // output: "123-456" "0-456" "123-"
  const priceStr =
    price?.min || price?.max ? `${price?.min || ''}-${price?.max || ''}` : '';

  return {
    multiFacetedBoatTypeClass,
    condition: conditionStr,
    state,
    price: priceStr,
  };
};

export const createBoatUrl = (params: BoatParams) => {
  const { year, make, model, id } = params;

  return encodeURI(`/boats/${year}/${make}/${model}/${id}`);
};

export const normalizeBoatData = (boat: RawBoat): NormalizedBoat => ({
  ...boat,
  price: boat.price.type?.amount?.USD,
  media: boat.media.map((media) => ({
    ...media,
    url: media.url.replace('http://', 'https://'),
  })),
});

export const formatBoatPrice = ({ price }: NormalizedBoat) =>
  price
    ? `${
        price
          .toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
          .split('.')[0] // remove cents
      }`
    : 'Request Price';

export const getBoatMediaUrl = (
  media: NormalizedBoatMedia,
  options?: { width?: number; height?: number; isPreview?: boolean }
) => {
  // Suggested dimensions:
  // w=300&h=222
  // w=400&h=267
  // w=480&h=350
  // w=1200&h=720
  // w=1600&h=800

  const { width, height, isPreview } = options ?? {};

  if (isPreview && media.videoVideoThumbnailUri)
    return media.videoVideoThumbnailUri;

  const params = new URLSearchParams();
  const timestamp = (
    media.date.modified ? new Date(media.date.modified) : new Date()
  ).getTime();

  params.set('t', String(timestamp));

  if (width != null) {
    params.set('w', String(width));
  }

  if (height != null) {
    params.set('h', String(height));
  }

  // You can technically set the quality
  //   if (quality != null) {
  //     params.set('q', String(quality));
  //   }

  return `${media.url}?${params.toString()}`;
};
