import { Badge } from '@nextui-org/react';
import React from 'react';

type Props = {
  condition: string;
};

const ConditionBadge = ({ condition }: Props) => {
  return (
    <Badge
      // variant="flat"
      isSquared
      enableShadow
      disableOutline
      css={{
        height: '25px',
      }}
      color={condition === 'new' ? 'success' : 'secondary'}
    >
      {condition}
    </Badge>
  );
};

export default ConditionBadge;
