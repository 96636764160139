import React from 'react';
import { Text, Spacer } from '@nextui-org/react';
import Link from 'next/link';
import BoatsListPreview from './BoatsListPreview';
import Routes from '../routes';

const SeeMoreBoatsLink = ({ filters, label }) => {
  const queryStrings =
    filters &&
    Object.keys(filters).reduce((acc, filterKey) => {
      const filterValue = (() => {
        const value = filters[filterKey];

        // condition is nested
        if (filterKey === 'condition') {
          if (value?.new) return 'new';
          if (value?.used) return 'used';
        }

        return filters[filterKey];
      })();
      if (filterValue) {
        return `${acc ? `${acc}&` : `?`}${filterKey}=${filterValue}`;
      }
    }, '');

  return (
    <Text size="1.4rem">
      <Link href={`${Routes['boats-search']}${queryStrings ?? ''}`}>
        {`View all ${label}`}
      </Link>
    </Text>
  );
};

const BoatPreviewSection = ({ title, filters }) => (
  <div
    css={{
      maxWidth: '100%',
      overflowX: 'hidden',
    }}
  >
    {/* <Spacer y={2} /> */}

    <Text h3 size="2rem">
      {title}
    </Text>
    <BoatsListPreview filters={filters} />

    <div
      css={{
        textAlign: 'center',
      }}
    >
      <Spacer />
      <SeeMoreBoatsLink filters={filters} label={title.toLowerCase()} />
    </div>
    <Spacer y={5} />
  </div>
);

export default BoatPreviewSection;
