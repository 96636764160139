import * as React from 'react';
import { Text, Loading, Spacer, Card, Button } from '@nextui-org/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { track } from '@amplitude/analytics-browser';

import Routes from '../routes';
import { useBoats } from '../hooks/boats';
import BoatCardList from './BoatCardList';
import BoatSearchFilters from './BoatSearchFilters';
import Loader from './Loader';
import QuickFilters from './QuickFilters';
import MailingListCTA from './MailingListCTA';

const BoatsListPreview = ({
  filters,
}: {
  filters?: {
    power: boolean;
    sail: boolean;
    condition: { new: boolean; used: boolean };
  };
}) => {
  const router = useRouter();

  //   const [filters, setFilters] = React.useState({
  //     power: false,
  //     sail: false,
  //     condition: {
  //       new: false,
  //       used: false,
  //     },
  //     make: null,
  //     model: null,
  //     year: null,
  //     price: null,
  //     length: null,
  //     location: {
  //       city: null,
  //       state: null,
  //       zip: null,
  //     },
  //   });

  const { isLoading, error, pages, totalCount, fetchNextPage } = useBoats({
    pageSize: 10,
    filters: filters ?? {},
  });

  const boats = pages?.[0]?.search?.records;
  if (error || !boats) return null;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        width: '100%',
        maxWidth: '100%',
        flexWrap: 'nowrap',
        overflowX: 'scroll',
      }}
    >
      {/* <InfiniteScroll
        dataLength={pages.length}
        hasMore={true}
        loader={<Loader />} // primary loader for the page is located here
        next={fetchNextPage}
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      > */}
      {pages?.map(({ search: { records = [] } = {} } = {}, idx) => (
        <BoatCardList
          key={`page_card_list_${idx}`}
          boats={boats}
          smallCards
          horizontal
        />
      ))}
      {/* </InfiniteScroll> */}
    </div>
  );
};

export default BoatsListPreview;
