import React from 'react';
import chalk from 'chalk';
import { NextSeo } from 'next-seo';
import { default as metadata } from '../seoMetaData.js';
export const meta = metadata;

const titleTemplate = (title) => `${title} | LendingBoat`;

export const createMeta = ({ title, description, route, images } = {}) => ({
  title: titleTemplate(title ?? meta.default.title),
  description: description ?? meta.default.description,
  images,
  openGraph: {
    type: 'website',
    title: titleTemplate(title ?? meta.default.title),
    locale: 'en_US',
    url: `${meta.url}${route}`,
    site_name: 'Lending.boats',
    description: description ?? meta.default.description,
    images,
  },
});

const SEO = ({ route }: { route: string }) => {
  if (!route || !meta[route]) {
    console.error(chalk.red(`No seo metadata found for: ${route} `));
  }
  return <NextSeo {...createMeta({ ...meta[route], route })} />;
};

export default SEO;
